<template>
  <div class="orderSubmit">
    <img src="../../../assets/submitEnding/post_success_result.png" alt />
    <h1>您的订单已提交成功</h1>
    <p class="goOrderCenter" @click="gopro">{{isFenxiao?'点击前往退货列表中心':'点击前往入库列表中心'}}</p>
    <div style="height:16px;"></div>
    <div class="copyOrder">
      <p class="copyName">申请单号</p>
      <p>{{ ordernum }}</p>
      <p class="orderCopy" :data-clipboard-text="ordernum" @click="copyOrder">
        复制
      </p>
    </div>
    <div class="btn">
      <div @click="goBuy">继续退货</div>
      <div class="returnCar" @click="goCar">{{isFenxiao?'返回退货列表':'返回入库列表'}}</div>
    </div>
  </div>
</template>
<script>
import Clipboard from "clipboard";
export default {
  name: "ordersubmit",
  data() {
    return {
      orderListItem: [],
      returnpath: "",
      returnname: "",
      orderRoute: {},
      ordernum: ""
    };
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.returnname = from.meta.titles;
    });
  },
  mounted() {
    this.ordernum = this.$route.query.b2bOrderCode;
  },
  methods: {
    // 退货列表中心
    gopro() {
      let isFenxiao = this.$store.state.user.userInfo.account.allFenXiao
      if (isFenxiao) {
              this.$router.push("/channelReturns/distributorReturnList")
      } else {
        this.$router.push("/channelReturns/agentWarehousingList")
      }
    },
    //   返回退货列表
    goCar() {
       let isFenxiao = this.$store.state.user.userInfo.account.allFenXiao
      if(isFenxiao) {
        this.$router.push("/channelReturns/distributorReturnList");
      } else {
        this.$router.push("/channelReturns/agentWarehousingList")
      }
    },
    // 继续退货
    goBuy() {
      this.$router.push("/channelReturns/returnEntry");
    },
    copyOrder() {
      var clipboard = new Clipboard(".orderCopy");
      clipboard.on("success", e => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }
  }
};
</script>
<style lang='less' scoped>
.orderSubmit {
  width: 1188px;
  //   height: 492px;
  padding-bottom: 70px;
  background: #fff;
  margin: 16px auto;
  .goOrderCenter {
    cursor: pointer;
    font-family: PingFang-SC-Medium;
    font-size: 16px;
    color: #00aaa6;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    margin-top: 8px;
  }
  img {
    margin-top: 70px;
  }
  h1 {
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: #262626;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;
    margin-top: 24px;
  }
  .copyOrder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 390px;
    padding: 12px 24px;
    box-sizing: border-box;
    background: #f7f7f7;
    margin: 0 auto;
    p {
      width: 30%;
      // margin: 16px auto 0;
      font-size: 14px;
      color: #262626;
      line-height: 20px;
      margin-top: 0;
    }
    .orderCopy {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1890ff;
    }
  }
  //   按钮
  .btn {
    width: 390px;
    margin: 40px auto 0;
    display: flex;
    justify-content: center;
    div {
      width: 118px;
      height: 40px;
      cursor: pointer;
      text-align: center;
      line-height: 40px;
      background: #00abaf;
      color: #fff;
      margin-right: 20px;
      border-radius: 4px;
    }
    .returnCar {
      background: #ffffff;
      border: 1px solid #00abaf;
      width: 116px;
      color: #00abaf;
    }
  }
}
</style>